import {ProductBrand} from "react-home-ar";
import {BrandConfig, SiteConfig, TranslationContent} from "cambrian-base";
import {ShawStateContext} from "./ShawContext";

type TranslationPrefix = "Button"|"Button.Filter"|"Text"|"Error"|"Button.Error"|"Button.Share"|"Text.Style"

export class DataClient {
    public constructor(private dataPath: string, private context:ShawStateContext) {
        console.log("New data client", this.dataPath);
        DataClient._instance = this;
    }

    private static _instance: DataClient;

    static get current(): DataClient {
        return DataClient._instance;
    }

    public async getSiteData() : Promise<SiteConfig> {
        return new Promise<SiteConfig>((resolve)=>{
            if (this.context.state.siteData){
                resolve(this.context.state.siteData);
                return;
            }
            return fetch(this.dataPath).then(resp => resp.json()).then(siteData=>{
                this.context.state.siteData = siteData;
                this.context.dispatch({
                    type: "setSiteData",
                    siteData: siteData
                });
                resolve(siteData);
            });
        });
    }

    public async getTranslationData(brandCode:string) : Promise<TranslationContent> {
        return new Promise<any>((resolve, reject)=>{
            if (this.context.state.translationData){
                resolve(this.context.state.translationData);
                return;
            }
            const dataPath = `${this.dataPath}/${brandCode}/translations`;

            return fetch(dataPath).then(resp => resp.json()).then(data=>{
                const dictionary:TranslationContent = {};
                if (data.hasOwnProperty("items")) {
                    data.items.forEach((item:any)=>{
                        const element = item.elements;
                        const key = (element.key.value as string).toLowerCase();
                        const value = element.value.value;
                        dictionary[key] = value;
                        if (process.env.REACT_APP_SHOW_TRANSLATIONS) {
                            dictionary[key] += ":t";
                        }
                    })
                }

                this.context.state.translationData = dictionary;
                if (process.env.REACT_APP_SHOW_TRANSLATIONS && data.hasOwnProperty("items")) {
                    console.log("Translations", dictionary);
                }
                resolve(dictionary);
            }).catch((error)=>{
                reject(error);
            });
        });
    }

    private matches(brand:BrandConfig|SiteConfig, code:string) {
        if (brand.subdomain && brand.subdomain.toLowerCase() === code.toLowerCase()) {
            return true
        }
        return brand.code.toLowerCase() === code.toLowerCase();
    }

    public getBrandConfig(code:string) : Promise<BrandConfig> {
        return this.getSiteData().then(siteData=>{
            const match = siteData.brands.find(b=>this.matches(b, code));
            if (match) {
                return match
            }
            return Promise.reject(`Brand ${code} not found.`)
        });
    }

    public getBrandData(code:string, query?:string) : Promise<ProductBrand> {
        return new Promise<ProductBrand>((resolve)=>{
            if (this.context.state.brand && this.context.state.brand.code === code.toLowerCase()) {
                return this.context.state.brand
            }

            this.getBrandConfig(code).then(config=>{
                if (!config) {
                    return Promise.reject("Invalid brand");
                }
                const brand = new ProductBrand();
                brand.load(config);
                brand.collections.forEach(col=>{
                    col.dataUrl = `${this.dataPath}/${brand.code}/${col.code}${query ? '?'+query:""}`;
                });

                if (query && (query.indexOf("dealerkey") >=0 || query.indexOf("builderkey") >= 0)) {
                    console.log("Special dealer or builder case");
                    const url = `${this.dataPath}/${brand.code}${query ? '?'+query:""}`
                    return fetch(url).then(resp => resp.json()).then(types=>{
                        brand.collections = brand.collections.filter(b=>types.indexOf(b.code) >= 0)
                        resolve(brand);
                    });
                } else {
                    resolve(brand);
                }
            })
        });
    }

    private _warnedFor:string[] = []

    private getTranslationKey(str:string) {
        let replacement = str.replace(" ", ".");
        replacement = replacement.replace("-", ".").toLowerCase();
        replacement = replacement.replace(/[^a-z\\._]/gi, "_").toLowerCase();

        return replacement;
    }

    public getTranslation(_key:string, defaultString:string):string {
        const key = this.getTranslationKey(_key);
        if (!this.context.state.translationData || Object.keys(this.context.state.translationData).length === 0) {
            if (process.env.REACT_APP_SHOW_TRANSLATIONS && this._warnedFor.indexOf("translationData") < 0) {
                this._warnedFor.push("translationData");
                console.warn("Server translation data not yet available or not available for this brand");
            }
        }
        else if (this.context.state.translationData) {
            if (this.context.state.translationData.hasOwnProperty(key)) {
                return this.context.state.translationData[key];
            } else if (process.env.REACT_APP_SHOW_TRANSLATIONS && this._warnedFor.indexOf(key) < 0) {
                this._warnedFor.push(key);
                console.warn(`Unknown translation key "${key}", using default "${defaultString}"`);
            }
        }
        return defaultString;
    }

    public translate(str:string, prefix:TranslationPrefix = "Button"):string {
        if (this.context.state.translationData && !str.endsWith(":t")) {
            const key = this.getTranslationKey(prefix + "." + str);
            return this.getTranslation(key, str);
        }
        return str;
    }
}