import {createContext, Dispatch} from "react"
import * as qs from "querystring"

import {BrowserProperties} from "react-client-info";
import {
    CBARAssetType,
    CBARSurfaceType, CBARTiledAsset,
    CBContentManager, DataItem,
    ProductBrand, ProductCollection,
    ProductColor
} from "react-home-ar";
import {ImageProperties} from "react-cambrian-ui";
import {SiteConfig, TranslationContent} from "cambrian-base";

export type SurfacePlaceholder = {
    name:string
    displayName:string
    surfaceType:CBARSurfaceType
    assetType:CBARAssetType
    asset?:CBARTiledAsset
    collection?:ProductCollection|undefined
    selectedSwatch?: DataItem|undefined
    selectedSubSwatch?: DataItem|undefined
    size?:string
    pattern?:string
}

export type SurfacePlaceholderParam = {
    index:number
    color:string
    product:string
    collection:string
    size?:string
    pattern?:string
    rotation?:number
    positionX?:number
    positionY?:number
}

enum SURFACE_URL_KEYS {
    color="color",
    product="product",
    collection="collection",
    size="size",
    pattern="pattern",
    rotation="rot",
    positionX="pos_x",
    positionY="pos_y"
}

const _surfaceToUrlParams = (searchObject:any, surface:SurfacePlaceholder, index:number) => {

    if (surface.asset && surface.asset.product) {
        const color = surface.asset.product as ProductColor
        searchObject[`${SURFACE_URL_KEYS.color}_${index}`] = color.code;
        searchObject[`${SURFACE_URL_KEYS.product}_${index}`] = color.product.code;
        searchObject[`${SURFACE_URL_KEYS.collection}_${index}`] = color.collection.code;
        if (surface.size) {
            searchObject[`${SURFACE_URL_KEYS.size}_${index}`] = surface.size;
        }
        if (surface.pattern) {
            searchObject[`${SURFACE_URL_KEYS.pattern}_${index}`] = surface.pattern;
        }

        searchObject[`${SURFACE_URL_KEYS.rotation}_${index}`] = Number(surface.asset.surfaceRotation).toFixed(3);
        searchObject[`${SURFACE_URL_KEYS.positionX}_${index}`] = Number(surface.asset.surfacePosition.x).toFixed(3);
        searchObject[`${SURFACE_URL_KEYS.positionY}_${index}`] = Number(surface.asset.surfacePosition.y).toFixed(3);
    }
}

const _parseSurfaceUrlParams = (searchObject:any,index:number) : SurfacePlaceholderParam|undefined => {
    const color = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.color}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.color}_${index}`] : undefined;
    const product = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.product}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.product}_${index}`] : undefined;
    const collection = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.collection}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.collection}_${index}`] : undefined;
    const size = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.size}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.size}_${index}`] : undefined;
    const pattern = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.pattern}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.pattern}_${index}`] : undefined;
    const rotation:number|undefined = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.rotation}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.rotation}_${index}`] : undefined;
    const positionX:number|undefined = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.positionX}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.positionX}_${index}`] : undefined;
    const positionY:number|undefined = searchObject.hasOwnProperty(`${SURFACE_URL_KEYS.positionY}_${index}`) ? searchObject[`${SURFACE_URL_KEYS.positionY}_${index}`] : undefined;
    if (color && product && collection) {
        return {index, color, product, collection, size, pattern, rotation, positionX, positionY}
    }
}

const clearSurfaceDataUrlParameters = (searchObject:any) => {
    for (let i=0; i<20; i++) {
        Object.values(SURFACE_URL_KEYS).forEach(value=>{
            const key = `${value}_${i}`;
            if (searchObject.hasOwnProperty(key)) {
                delete searchObject[key];
            }
        })
    }
}

const setSurfaceDataUrlParameters = (searchObject:any, surfaces:SurfacePlaceholder[]) => {
    clearSurfaceDataUrlParameters(searchObject);

    let index = 0;
    surfaces.forEach(s=>{
        _surfaceToUrlParams(searchObject, s, index ++);
    })
}

export const parseSurfaceDataUrlParameters = (searchObject:any) : SurfacePlaceholderParam[] => {
    const params: SurfacePlaceholderParam[] = [];

    //legacy: ?category=resilient&sellingstylenbr=1020V&sellingcolornbr=01005
    if (searchObject.category && searchObject.sellingstylenbr && searchObject.sellingcolornbr) {
        params.push({index:0,
            color:searchObject.sellingcolornbr,
            product:searchObject.sellingstylenbr,
            collection:searchObject.category});
    } else {
        //new: just add what we find
        for (let i=0; i<20; i++) {
            const param = _parseSurfaceUrlParams(searchObject, i);
            if (param) params.push(param);
        }
    }
    return params;
}

export type SharableShawState = {
    siteData: SiteConfig | undefined | null
    region: string | undefined | null
    translationData: TranslationContent | undefined | null
    brand: ProductBrand | undefined | null
    surfaceData:SurfacePlaceholder[] | undefined | null,
    surfaceDataParams:SurfacePlaceholderParam[] | undefined | null,

    selectedRoom: string | undefined | null
    selectedSubroom: string | undefined | null
    selectedSampleRoom: string | undefined | null
    selectedSampleRoomType: string | undefined | null

    dealerKey:string | undefined | null
    builderKey:string | undefined | null
    utmCampaign:string | undefined | null
}

export type DerivedShawState = {
    browserProperties:BrowserProperties,
    error: Error | undefined | null
}

export type DerivedVisualizerState = {
    sceneData:ImageProperties | undefined | null
}

export type ShawState = SharableShawState & DerivedShawState & DerivedVisualizerState

export function createEmptyShawState(): ShawState {
    return {
        // Shaw shared
        siteData: undefined,
        region:undefined,
        translationData:undefined,
        brand: undefined,
        surfaceData:undefined,
        surfaceDataParams:undefined,

        selectedRoom:undefined,
        selectedSubroom:undefined,
        selectedSampleRoom: undefined,
        selectedSampleRoomType: undefined,

        dealerKey:undefined,
        builderKey:undefined,
        utmCampaign:undefined,

        // Shaw derived
        browserProperties:{},
        error: undefined,

        // Visualizer derived
        sceneData: undefined,
    }
}

export enum ShawImagePaths {
    Shared = "assets/img/shared/",
    Home = "assets/img/home/",
    ChooseSource = "assets/img/choose-source/",
    Brands = "assets/img/brands/",
    SampleImages = "assets/img/sample-images/",
    MaterialTypes = "assets/img/material-types/",
    Visualizer = "assets/img/visualizer/",
    Swatches = "assets/img/swatches/",
    Scenes = "assets/scenes/"
}

export type ShawStateContext = {
    state: ShawState
    dispatch: Dispatch<ShawAction>
}

export function GAnalyticsPush(data:any) {
    const dataLayer = (window as any).dataLayer;
    dataLayer.push(data)
}

const getSpecification = (color:ProductColor, code:string) => {
    return color.details?.specifications?.find(s=>s.code === code);
}

export function GAnalyticsProductView(eventName:string, nodeName:string, type:string, color:ProductColor, list:string|undefined) {

    let version = undefined;
    if (color.json.hasOwnProperty('ColorFamilyDesc')) {
        version = color.json['ColorFamilyDesc']
    }

    const price = getSpecification(color, "Price");
    const size = getSpecification(color, "SizeDesc");

    const eventData = {
        actionField: {
            list: list ? list : "unfiltered"
        },
        products: [
            {
                name: `${color.product.displayName} ${color.displayName}`,
                id: color.code,
                price: price ? price.displayValue : undefined,
                brand: color.brand.displayName,
                category: color.collection.displayName ? color.collection.displayName.charAt(0).toUpperCase() + color.collection.displayName.slice(1) : undefined,
                variant: color.code,
                dimension3: size ? size.displayValue : undefined,
                dimension4: version,
                dimension6: undefined,
                metric4: 1,
            }
        ]
    };

    let data:any = {
        event: eventName,
        ecommerce: {currencyCode: "USD"}
    };

    data["ecommerce"][nodeName] = eventData;

    GAnalyticsPush(data)
}

export function GAnalyticsPageImpression(colors:ProductColor[], list:string|undefined) {

    const impressions:any[] = [];
    for (let color of colors) {
        const impression = {
            name: `${color.json['SellingStyleName']} ${color.json['SellingColorName']}`,
            id: color.code,
            price: color.json.hasOwnProperty('Price') ? color.json['Price'] : undefined,
            brand: color.brand.displayName,
            category: color.collection.displayName ? color.collection.displayName.charAt(0).toUpperCase() + color.collection.displayName.slice(1) : undefined,
            variant: color.json['SellingColorNbr'],
            list: list ? list : "unfiltered",
            position:color.orderIndex,
            dimension3: color.json.hasOwnProperty('SizeDesc') ? color.json['SizeDesc'] : undefined,
            dimension4: color.json.hasOwnProperty('ColorFamilyDesc') ? color.json['ColorFamilyDesc'] : undefined,
            dimension5: undefined,
            dimension6: undefined,
            metric4: 1,
        };
        impressions.push(impression)
    }

    let data:any = {
        event: `product-impression`,
        ecommerce: {
            currencyCode: "USD",
            impressions: impressions
        }
    };

    GAnalyticsPush(data)
}

export const clearPlaceholderAsset = (placeholder:SurfacePlaceholder)=>{

    if (placeholder.asset?.product) {
        const color = placeholder.asset.product as ProductColor;
        color.selected = false;
        color.product.selected = false;
        color.product.collection.selected = false;
        placeholder.collection = undefined;
        placeholder.asset = undefined;
        placeholder.selectedSwatch = undefined;
        placeholder.selectedSubSwatch = undefined;
        placeholder.pattern = undefined;
        placeholder.collection = undefined;
        placeholder.size = undefined;
    }
}

function createUndefinedShawStateContext(): ShawStateContext | undefined {
    return undefined
}

export type ShawActionSetSiteData = {
    type: "setSiteData"
    siteData: SiteConfig | undefined | null
}

export type ShawActionSetRegion = {
    type: "setRegion"
    region: string
}

export type ShawActionSetTranslationData = {
    type: "setTranslationData"
    translationData: TranslationContent | undefined | null
}

export type ShawActionSetBrandData = {
    type: "setBrandData"
    brand: ProductBrand | undefined | null
}

export type ShawActionSetSurfaceData = {
    type: "setSurfaceData"
    surfaceData: SurfacePlaceholder[] | undefined | null
}

export type ShawActionSetSurfaceDataParams = {
    type: "setSurfaceDataParams"
    surfaceDataParams: SurfacePlaceholderParam[] | undefined | null
}

export type ShawActionSetSelectedRoom = {
    type: "setSelectedRoom"
    room: string | undefined | null,
    subroom: string | undefined | null
}

export type ShawActionSetSelectedSampleRoom = {
    type: "setSelectedSampleRoom"
    selectedSampleRoom: string | undefined | null
}

export type ShawActionSetSelectedSampleRoomType = {
    type: "setSelectedSampleRoomType"
    selectedSampleRoomType: string | undefined | null
}

export type ShawActionSetUtmCampaign = {
    type: "setUtmCampaign"
    campaign: string | undefined | null
}

export type ShawActionSetBrowserProperties = {
    type: "setBrowserProperties"
    properties: BrowserProperties
}

export type ShawActionSetError = {
    type: "setError"
    error: Error | undefined | null
}

export type ShawActionSetSceneData = {
    type: "setSceneData"
    sceneData: ImageProperties | undefined | null
}

export type ShawActionSetOnMaterialTextureChanged = {
    type: "setOnMaterialTextureChanged"
    onMaterialTextureChanged: ((path: string) => (void)) | null
}

export type ShawActionClearProductSelections = {
    type: "clearProductSelections"
}

export type ShawActionClearSceneProperties = {
    type: "clearSceneProperties"
}

export type ShawActionSetDealerKey = {
    type: "setDealerKey"
    dealerKey: string | undefined | null
}

export type ShawActionSetBuilderKey = {
    type: "setBuilderKey"
    builderKey: string | undefined | null
}

//For git merge simplicity, append to the end of this list on a new line, per pull request
export type ShawAction = ShawActionSetSiteData | ShawActionSetRegion | ShawActionSetTranslationData | ShawActionSetBrandData | ShawActionSetSurfaceData |
    ShawActionSetSelectedSampleRoom | ShawActionSetSelectedSampleRoomType | ShawActionSetSelectedRoom |
    ShawActionSetError | ShawActionSetUtmCampaign | ShawActionSetSceneData | ShawActionSetDealerKey |
    ShawActionSetOnMaterialTextureChanged | ShawActionSetBrowserProperties | ShawActionSetBuilderKey |
    ShawActionClearProductSelections | ShawActionClearSceneProperties | ShawActionSetSurfaceDataParams

export function shawStateReducer(state: ShawState, action: ShawAction): ShawState {
    // Set the thing we are supposed to set. Also make sure anything depending
    // on the thing we set gets reset to null.
    const newState: ShawState = { ...state };

    switch (action.type) {
        // Shaw underived, null everything below in the hierarchy

        case "setSiteData":
            newState.siteData = action.siteData;
            break;

        case "setRegion":
            newState.region = action.region.toLowerCase();
            break;

        case "setTranslationData":
            newState.translationData = action.translationData;
            break;

        case "setBrandData":
            newState.brand = action.brand;
            break;

        case "setDealerKey":
            newState.dealerKey = action.dealerKey;
            break;

        case "setBuilderKey":
            newState.builderKey = action.builderKey;
            break;

        case "setSurfaceData":
            newState.surfaceData = action.surfaceData;
            break;

        case "setSurfaceDataParams":
            newState.surfaceDataParams = action.surfaceDataParams;
            break;

        case "setSelectedRoom":
            newState.selectedRoom = action.room;
            newState.selectedSubroom = action.subroom;
            break;
        case "setSelectedSampleRoom":
            newState.selectedSampleRoom = action.selectedSampleRoom;
            break;
        case "setSelectedSampleRoomType":
            newState.selectedSampleRoomType = action.selectedSampleRoomType;
            break;

        case "setUtmCampaign":
            newState.utmCampaign = action.campaign;
            break;


        case "setBrowserProperties":
            newState.browserProperties = action.properties;
            break;

        case "setError":
            newState.error = action.error;
            break;

        // Visualizer derived
        case "setSceneData":
            newState.sceneData = action.sceneData;
            break;

        case "clearProductSelections":
            //console.log("Clear product selections");
            if (newState.surfaceData) {
                newState.surfaceData.forEach(p=>clearPlaceholderAsset(p));
            }
            newState.surfaceData = undefined;
            break;

        case "clearSceneProperties":
            //console.log("Clear scene properties");
            newState.selectedSampleRoomType = undefined;
            newState.selectedSampleRoom = undefined
            newState.selectedRoom = undefined;
            newState.selectedSubroom = undefined;
            newState.sceneData = undefined;
            break;

        default:
            throw new Error("Invalid action: " + action.type)
    }

    return newState
}

export const ShawContext = createContext(createUndefinedShawStateContext());

export function redirectKeepSearch(props: any, target: string) {
    // Important: in the useEffect in index.tsx we listen to state changes and change
    // the window's history with pushState. This is not the same history as react's
    // so we need to use the search of the window here.
    // Ideally we modify react's history in index.tsx instead.

    let targetParams = '';
    if (target.indexOf('?') >= 0) {
        const parts = target.split('?');
        target = parts[0];
        targetParams = (window.location.search.indexOf('?') >= 0  ? '&' : '?') + parts[1]
    }

    const url = `${target}${window.location.search}${targetParams}`;

    props.history.push(url)
}

export function stateToUrl(shawState: ShawState) {
    // Check if current search parameters are the same as the one constructed from search.
    // If not, modify the URL without navigating to it.

    const oldSearchObject = qs.parse(window.location.search.substr(1));
    const searchObject: any = {...oldSearchObject};

    const includeSceneParams = window.location.href.indexOf("visualizer") > 0

    if (!shawState.siteData?.server?.subdomain && shawState.siteData?.server?.isLocalhost && shawState.brand && shawState.brand.json.subdomain) {
        searchObject.subdomain = shawState.brand.json.subdomain
    }

    if (shawState.selectedSampleRoom) {
        searchObject.r = shawState.selectedSampleRoom
    }

    if (shawState.selectedSampleRoomType) {
        searchObject.rt = shawState.selectedSampleRoomType
    }

    if (includeSceneParams) {
        if (shawState.sceneData) {
            CBContentManager.default.synchronize(searchObject);
            if (searchObject.room) {
                shawState.selectedRoom = searchObject.room;
                shawState.selectedSubroom = searchObject.subroom;
                delete searchObject.r
                delete searchObject.rt
            }
        }
    }

    if (shawState.dealerKey) {
        searchObject.dealerkey = shawState.dealerKey
    }

    if (shawState.builderKey) {
        searchObject.builderkey = shawState.builderKey
    }

    if (shawState.utmCampaign) {
        searchObject.utm_campaign = shawState.utmCampaign
    }

    if (shawState.region) {
        searchObject.region = shawState.region
    }

    if (shawState.surfaceData && (shawState.selectedRoom || shawState.selectedSampleRoom)) {
        setSurfaceDataUrlParameters(searchObject, shawState.surfaceData);
    }
    // else if (!shawState.selectedRoom) {
    //     delete searchObject.room
    // }
    CBContentManager.default.synchronize(searchObject);

    let search = qs.stringify(searchObject);
    if (search.length > 0) {
        search = `?${search}`
    }

    return `${window.location.origin}${window.location.pathname}${search}`
}
