import React, {ReactNode, useCallback, useContext, useEffect} from "react"

import './Navigation.css'
import { TransitionGroup, Transition} from 'react-transition-group'
import {LocationKey} from "history"
import {ShawContext, stateToUrl} from "../data/ShawContext";

const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export function isOldBrowser() {
    // eslint-disable-next-line no-eval
    try { eval("var foo = (x)=>x+1") }
    catch (e) { return true }
    return false
}

const isAnimationDisabled = isOldBrowser() || isMobile.any();

let popStateKey:string|undefined = undefined;
let isBack = false;

window.onpopstate = (e:PopStateEvent) => {
    if (e.state) {
        popStateKey = e.state.key
    }
};

type NavigationProps = {
    navKey?:LocationKey,
    children?: ReactNode,
}

//CSSTransitions:
//https://tylermcginnis.com/react-router-animated-transitions/
export function Navigation(props: NavigationProps) {

    const shawContext = useContext(ShawContext)!;

    function determineDirection() {
        isBack = popStateKey === props.navKey || document.location.pathname === "/"
    }

    function setDirectionClass(node: HTMLElement) {
        node.classList.remove("backward");
        node.classList.remove("forward");
        node.classList.add(isBack ? "backward" : "forward")
    }

    function removeAllClasses(node: HTMLElement) {
        node.classList.remove("selected");
        node.classList.remove("dismiss");

        node.classList.remove("backward");
        node.classList.remove("forward")
    }

    function onEnter(node: HTMLElement, isAppearing: boolean) {
        if (isAnimationDisabled) return;

        determineDirection();
        setDirectionClass(node)
    }

    function onEntering(node: HTMLElement, isAppearing: boolean) {
        if (isAnimationDisabled) return;

        node.classList.remove("dismiss");
        node.classList.add("selected")
    }

    function onEntered(node: HTMLElement, isAppearing: boolean) {
        if (isAnimationDisabled) return;
        node.classList.remove("selected")
    }

    function onExit(node: HTMLElement) {
        if (isAnimationDisabled) return;
        setDirectionClass(node);

        node.classList.remove("selected")
    }

    function onExiting(node: HTMLElement) {
        if (isAnimationDisabled) return;
        node.classList.add("dismiss")
    }

    function onExited(node: HTMLElement) {
        if (isAnimationDisabled) return;
        removeAllClasses(node)
    }

    useEffect(() => {
        const url = stateToUrl(shawContext.state);
        if (url !== window.history.state) {
            window.history.replaceState({}, "", url)
        }
    }, [shawContext.state]);

    return (
        <TransitionGroup>
            <Transition key={props.navKey} timeout={isAnimationDisabled ? 0 : 500} unmountOnExit={true}
                        onEnter={onEnter} onEntering={onEntering} onEntered={onEntered}
                        onExit={onExit} onExiting={onExiting} onExited={onExited}>
                <div className="navigation-page">
                    {props.children}
                </div>
            </Transition>
        </TransitionGroup>
    )
}

type NavigationMenuProps = {
    title: string,
    history:any,
    backTitle?: string,
    hidden?:boolean
    shareable?:boolean
    url?:string
}

export function NavigationMenu(props: NavigationMenuProps) {
    const {history, url} = props;

    const goBack = useCallback(()=>{
        if (url) {
            history.replace(url)
        } else {
            history.goBack();
        }
    }, [history, url])

    return (
        <aside>
            {props.hidden ? null
                : <div className="page-heading">
                    <div className="navigation-menu">
                        <div className="navigation-back-button-container">
                            <button className="navigation-back-button" onClick={() => goBack()}>
                                <i className="arrow left" />
                                {props.backTitle ? props.backTitle : "Back"}
                            </button>
                        </div>
                        <div className="navigation-heading-container">
                            <h2>{props.title}</h2>
                        </div>
                    </div>
                </div>}
        </aside>
    )
}
