import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import cssVars from 'css-vars-ponyfill'
import './main.css'

import React, {useReducer, useEffect, useState, useCallback, useRef, lazy, Suspense, useMemo} from "react"
import * as ReactDOM from "react-dom"

import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"

import {
    createEmptyShawState,
    shawStateReducer,
    ShawContext,
    parseSurfaceDataUrlParameters,
} from './data/ShawContext'

import { Navigation } from "./components/Navigation"

import * as qs from "querystring"

import {BrowserProperties, WebClientInfo} from "react-client-info";

import { polyfill } from "smoothscroll-polyfill";
import {CBContentManager, cbInitialize} from "react-home-ar";
import {DataClient} from "./data/DataClient";
import {ConfigItem, ServerConfig} from "cambrian-base";

const RotateDeviceWarning = lazy(() => import('./components/RotateDeviceWarning'));
const ErrorHandler = lazy(() => import('./components/ErrorHandler'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Visualizer = lazy(() => import('./pages/Visualizer'));
const ChooseSource = lazy(() => import('./pages/ChooseSource'));
const SampleRoomTypes = lazy(() => import('./pages/SampleRoomTypes'));
const SampleImageListing = lazy(() => import('./pages/SampleImageListing'));

polyfill();

const objectFitImages = require('object-fit-images');

export const shareImageSize = [1000,600];

let ALLOW_WINDOW_SCROLL = false;

export function setWindowScroll(canScroll:boolean) {
    ALLOW_WINDOW_SCROLL = canScroll
}

export let domainInfo:ServerConfig = (window as any).domainInfo;
if (!domainInfo && process.env.REACT_APP_DEFAULT_HOSTING_URL && process.env.REACT_APP_DEFAULT_SIGNING_URL && process.env.REACT_APP_DEFAULT_PROCESSING_URL) {
    domainInfo = {
        domain: "localhost",
        subdomain: "",
        isStaging: false,
        isLocalhost: true,
        hostingUrl: process.env.REACT_APP_DEFAULT_HOSTING_URL,
        signingUrl: process.env.REACT_APP_DEFAULT_SIGNING_URL,
        processingUrl: process.env.REACT_APP_DEFAULT_PROCESSING_URL
    };
}

cbInitialize({
    ...domainInfo,
    placeholderPath:"assets/img/visualizer/blue-tile.jpeg"
})

export const CompareConfigItems = (a:ConfigItem,b:ConfigItem) => {
    if (a.orderIndex === b.orderIndex) return 0;
    else if (a.orderIndex === undefined) return 1;
    else if (b.orderIndex === undefined) return -1;

    return a.orderIndex < b.orderIndex ? -1 : 1;
}

let isTouching = false;

window.addEventListener('scroll', ()=>{
    if (!ALLOW_WINDOW_SCROLL && window.scrollY !== 0 && !isTouching){
        window.scrollTo(0, 0)
    }
});

let touchTimeout = 0;
document.body.addEventListener('touchstart', ()=>{
    touchTimeout = window.setTimeout(()=>{
        isTouching = true;
        window.scrollTo(0, 0)
    }, 100)
});

function cancelTouchScroll() {
    if (touchTimeout) clearTimeout(touchTimeout);
    isTouching = false;
    window.scrollTo(0, 0)
}

document.body.addEventListener('touchend', cancelTouchScroll);
document.body.addEventListener('touchcancel', cancelTouchScroll);

// Turn all the object's keys into lowercase with the same values
export function objectToLowerCase(object: any) {
    const newObject: any = {};

    for (const key of Object.keys(object)) {
        newObject[key.toLocaleLowerCase()] = object[key]
    }

    return newObject
}

// function cleanInput(input: string) {
//     return input.replace(/[^\w-]/g, '')
// }

function App() {
    // Create "global" shaw state and its update function
    const initialShawState = createEmptyShawState();
    const [shawState, dispatchShawState] = useReducer(shawStateReducer, initialShawState);

    // Url load states
    const [serverConfig, setServerConfig] = useState<ServerConfig>();
    const [brandToLoad, setBrandToLoad] = useState<string>();
    const [brandQueryToLoad, setBrandQueryToLoad] = useState<string>();
    const [browserProperties, setBrowserProperties] = useState<BrowserProperties>({});

    const searchObject = objectToLowerCase(qs.parse(window.location.search.substr(1)));

    const region = useMemo(()=>{
        return searchObject.region ? searchObject.region : "en-us"
    }, [searchObject.region])

    useEffect(()=>{
        if (!DataClient.current) {
            const dataPath = `${process.env.REACT_APP_DATA_URL ? process.env.REACT_APP_DATA_URL : ""}/data/${region}`
            const client = new DataClient(dataPath, {state:shawState, dispatch:dispatchShawState});
            client.getSiteData().then((config)=>{
                if (config.server) {
                    setServerConfig(config.server);
                }
            });
        }
    }, [shawState, dispatchShawState, region]);

    const setCssVars = useCallback(() => {
        if (!browserProperties.browser) return;

        const doc = document.documentElement;

        //without this check, causes WebGL flicker on desktop
        const width = window.innerWidth;
        const height = window.innerHeight;

        doc.style.setProperty("--app-height",  `${height}px`);
        doc.style.setProperty("--inverse-app-height", `${-height}px`);
        doc.style.setProperty("--half-app-height", `${height / 2}px`);
        doc.style.setProperty("--inverse-half-app-height", `${-height / 2}px`);
        doc.style.setProperty("--app-width", `${width}px`);
        doc.style.setProperty("--inverse-app-width", `${-width}px`)

    }, [browserProperties.browser]);

    // Load material data when selected material type changes
    useEffect(() => {
        if (browserProperties.isPortrait !== undefined) {
            window.setTimeout(() => {
                window.scrollTo(0, 1)
            }, 500)
        }
    }, [browserProperties.isPortrait]);

    useEffect(() => {
        dispatchShawState({ type: "setBrowserProperties", properties: browserProperties });
        setCssVars()
    }, [browserProperties, setCssVars]);

    useEffect(() => {
        if (browserProperties.hasTouchpad) {
            document.documentElement.style.setProperty("--scrollbar-style", "none");
            document.documentElement.style.setProperty("--scrollbar-display", "none");
            document.documentElement.style.setProperty("--scrollbar-thickness", "0px");
            document.documentElement.style.setProperty("--swatch-listing-scroller-height", "135px")
        }
    }, [browserProperties.hasTouchpad]);

    //component mounted:
    useEffect(() => {

        cssVars();
        objectFitImages();

        return () => {
            //unmount
        }
    }, []);

    useEffect(() => {
        // Load categories shaw state once when App is created
        if (brandToLoad) {
            //console.log("Getting brand", brandToLoad);
            DataClient.current.getBrandData(brandToLoad, brandQueryToLoad).then((brand)=>{
                dispatchShawState({ type: "setBrandData", brand: brand })
            });

            DataClient.current.getTranslationData(brandToLoad).then((content)=>{
                dispatchShawState({
                    type: "setTranslationData",
                    translationData: content
                });
            });

            setBrandToLoad(undefined);
        }

    }, [brandToLoad, brandQueryToLoad]);

    const _setBrowserProperties = useCallback((props:BrowserProperties)=>{
        setBrowserProperties(props)
    }, []);

    const updateFromLocation = useCallback(async (config:ServerConfig) => {

        // Parse URL search string without the first character (typically question mark).
        // Also turn the keys into lowercase so their case doesn't matter.
        CBContentManager.default.synchronize(searchObject);
        if (searchObject.region) {
            dispatchShawState({
                type: "setRegion",
                region: searchObject.region
            })
        }

        if (searchObject.rt) {
            if (!shawState.selectedSampleRoomType || searchObject.rt !== shawState.selectedSampleRoomType) {
                dispatchShawState({
                    type: "setSelectedSampleRoomType",
                    selectedSampleRoomType: searchObject.rt as string
                })
            }

            if (searchObject.r && (!shawState.selectedSampleRoom || searchObject.r !== shawState.selectedSampleRoom)) {
                dispatchShawState({
                    type: "setSelectedSampleRoom",
                    selectedSampleRoom: searchObject.r as string
                });
            }
        } else if (searchObject.room) {
            dispatchShawState({
                type: "setSelectedRoom",
                room: searchObject.room,
                subroom: searchObject.subroom
            });

            const basePath = `${config.hostingUrl}/${searchObject.room}`;
            await fetch(basePath + "/data_v3.json")
                .then(res => res.json())
                .then(data => {
                    //console.log("Scene data", data)
                    dispatchShawState({
                        type: "setSceneData",
                        sceneData: data
                    });
                }).catch(()=>{
                    dispatchShawState({
                        type: "clearSceneProperties"
                    });
                })
        }

        if (!shawState.brand) {
            if (config.subdomain) {
                setBrandToLoad(config.subdomain)
            }
            else if (searchObject.hasOwnProperty("subdomain")) {
                setBrandToLoad(searchObject.subdomain)
            }
        }

        const surfaceData = parseSurfaceDataUrlParameters(searchObject);

        if (surfaceData) {
            dispatchShawState({
                type:"setSurfaceDataParams",
                surfaceDataParams:surfaceData
            });
        }

        const dealerKey = searchObject.dealerkey as string;
        const builderKey = searchObject.builderkey as string;

        if (dealerKey && dealerKey !== shawState.dealerKey) {
            //&dealerkey=VCYKG
            dispatchShawState({
                type: "setDealerKey",
                dealerKey: dealerKey
            });
            setBrandQueryToLoad(`dealerkey=${dealerKey}`)
        } else  if (builderKey && builderKey !== shawState.builderKey) {
            dispatchShawState({
                type: "setBuilderKey",
                builderKey: builderKey
            })
            setBrandQueryToLoad(`builderkey=${builderKey}`)
        }

        if (searchObject.utm_campaign) {
            dispatchShawState({
                type: "setUtmCampaign",
                campaign: decodeURI(searchObject.utm_campaign)
            })
        }

    }, [searchObject, shawState.brand, shawState.builderKey, shawState.dealerKey, shawState.selectedSampleRoom, shawState.selectedSampleRoomType]);

    const initialize = useCallback(() => {
        setCssVars();
        window.addEventListener("resize", setCssVars);
        window.addEventListener("orientation", setCssVars);
        window.setInterval(()=>{
            setCssVars()
        }, 500)

    }, [setCssVars]);

    const initializeRef = useRef(initialize);
    useEffect(() => { initializeRef.current = initialize; }, [initialize]);

    useEffect(() => {
        if (initializeRef.current) {
            initializeRef.current()
        }
    }, []);

    const [hasUpdated, setHasUpdated] = useState(false)
    useEffect(()=>{
        if (serverConfig && !hasUpdated) {
            setHasUpdated(true);
            updateFromLocation(serverConfig).then(()=>{
                //console.log("new frontend pipeline initialized");
            });
        }
    }, [hasUpdated, serverConfig, updateFromLocation])

    return (
        <Router>
            <Route
                render={({ location }) => {
                    return (
                        <ShawContext.Provider value={{ state: shawState, dispatch: dispatchShawState }}>

                            <React.Suspense fallback={null}>
                                <RotateDeviceWarning />
                            </React.Suspense>

                            <React.Suspense fallback={null}>
                                <ErrorHandler />
                            </React.Suspense>

                            <WebClientInfo onClientStateChanged={_setBrowserProperties} />

                            <Navigation navKey={location.key}>
                                <Switch location={location}>

                                    <Route exact path="/" render={(props) => (<Suspense fallback={null}><HomePage {...props} /></Suspense>)}/>
                                    <Route exact path="/choose-source" render={(props) => (<Suspense fallback={null}><ChooseSource {...props} /></Suspense>)}/>
                                    <Route exact path="/sample-images" render={(props) => (<Suspense fallback={null}><SampleRoomTypes {...props} /></Suspense>)}/>
                                    <Route exact path="/sample-image-listing" render={(props) => (<Suspense fallback={null}><SampleImageListing {...props} /></Suspense>)}/>
                                    <Route exact path="/visualizer" render={(props) => (<Suspense fallback={null}><Visualizer {...props} /></Suspense>)}/>

                                    <Route>
                                        <Redirect to="/"/>
                                    </Route>
                                </Switch>
                            </Navigation>
                        </ShawContext.Provider>
                    )
                }}
            />
        </Router>
    )
}

ReactDOM.render(
    <App />,
    document.getElementById("root")
);